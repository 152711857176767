<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.5s;
}

.btn1 {
	margin-bottom: 10px;
	width: 250px;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>

<template>
	<errorContainer :error="erreur">
		<div v-if="Users">
			<div class="w-auto">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
					{{ this.FormMSG(10000, 'Crew setup') }}
				</h1>
			</div>
			<div :class="`${$screen.width <= 576 ? 'my-project' : ''}`">
				<transition name="slide">
					<b-tabs v-model="indexOfTab">
						<b-tab lazy v-if="canSeeApprove && !isForProjects && !isForGreenshotUser && useDoc" :title="FormMSG(1600, 'Approve')">
							<ValidateDocuments />
						</b-tab>
						<b-tab v-if="canSeeUserSetting || (isDev && isForProjects) || (isDev && isForGreenshotUser)" lazy :title="FormMSG(1, 'Users')">
							<b-card no-body class="card-border-blue-light form p-4">
								<b-row v-if="Users && $screen.width >= 992" class="form">
									<b-col sm="12" md="12" lg="6" xl="6" class="pb-3">
										<b-input-group v-if="$screen.width >= 992">
											<filter-input-search @filtered-users="filteredUsers" @handle-init-filter="onHandleInitFilter" />
										</b-input-group>
									</b-col>
								</b-row>
								<CardListBuilder
									v-if="$screen.width < 992"
									:items="Users"
									:fields="Mobilefields"
									:omittedFields="['isProd', 'isAdmin', 'validationLevel', 'pict', 'canAssign', 'Screen Access']"
								>
									<template slot="body" slot-scope="data">
										<b-row style="margin-top: 20px">
											<b-col md="12">
												<b-form-select
													v-model="data.item.validationLevel"
													:options="optionsForValidationLevel"
													@change="manageValidationChange(data.item)"
													id="valLevel"
													size="md"
												/>
											</b-col>
										</b-row>
										<b-row class="lh-22" style="margin-top: 20px; flex-direction: row">
											<b-col xs="6">
												<b class="mb-2">{{ FormMSG(1000, 'Screen Access') }}</b>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageCrewList"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageCrewList', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2970, 'Manage crew list') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageDocuments"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageDocuments', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2971, 'Manage documents') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageDocumentPackage"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageDocumentPackage', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2440, 'Manage document package') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageCallSheets"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageCallSheets', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2450, 'Manage call sheets') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageAccommodations"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageAccommodations', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2973, 'Manage accomodations') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageLocations"
														:value="true"
														v-if="useLoc"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageLocations', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2449, 'Manage locations') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canExportImport"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canExportImport', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2455, 'Can import/export') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canViewDepartmentBudget"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canViewDepartmentBudget', $event, data.item, screenAccess)"
													>
														{{ FormMSG(2690, 'View department budget') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canViewGlobalBudget"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canViewGlobalBudget', $event, data.item, screenAccess)"
													>
														{{ FormMSG(24430, 'View global budget') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canAccessGreenReporting"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canAccessGreenReporting', $event, data.item, screenAccess)"
													>
														{{ FormMSG(24560, 'View Green reporting screens') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageCarbonRemoval"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageCarbonRemoval', $event, data.item, screenAccess)"
													>
														{{ FormMSG(24480, 'Manage carbon removal') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canManageTipOfTheDay"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canManageTipOfTheDay', $event, data.item, screenAccess)"
													>
														{{ FormMSG(24405, 'Manage tip of the day') }}
													</b-form-checkbox>
												</b-col>
												<b-col md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canAccessConfig"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel('canAccessConfig', $event, data.item, screenAccess)"
													>
														{{ FormMSG(246, 'Can access config') }}
													</b-form-checkbox>
												</b-col>
												<b-col v-if="isPme" md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.canAssign"
														:value="true"
														:unchecked-value="false"
														@change="handleAccessLevel(canAssign, $event, data.item, screenAccess)"
													>
														{{ FormMSG(30, 'Planning Manager') }}
													</b-form-checkbox>
												</b-col>
												<b-col v-if="!isForProject" md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.isDev"
														:value="true"
														:unchecked-value="false"
														@change="manageIsDevChange($event, data.item)"
													>
														{{ FormMSG(42, 'View TheGreenShot Menu') }}
													</b-form-checkbox>
												</b-col>
												<b-col v-if="!isForProject" md="12" class="mb-3">
													<b-form-checkbox
														size="sm"
														v-model="data.item.isTranslator"
														:value="true"
														:unchecked-value="false"
														@change="manageIsTranslatorChange($event, data.item)"
													>
														{{ FormMSG(43, 'Translator') }}
													</b-form-checkbox>
												</b-col>
											</b-col>
											<b-col xs="6">
												<b class="mb-2">{{ FormMSG(1001, 'Role') }}</b>
												<b-form-checkbox
													size="sm"
													class="mb-3"
													v-model="data.item.gateKeeper"
													@change="handleAccessLevel(gateKeeper, $event, data.item, screenAccess)"
												>
													{{ FormMSG(266, 'Gate Keeper') }}
												</b-form-checkbox>
												<b-form-checkbox
													size="sm"
													class="mb-3"
													v-model="data.item.productionAccountant"
													@change="handleAccessLevel(productionAccountant, $event, data.item, screenAccess)"
												>
													{{ FormMSG(261, 'Production Account') }}
												</b-form-checkbox>
												<b-form-checkbox size="sm" class="mb-3" v-model="data.item.upm" @change="manageUpmChange($event, data.item)">
													{{ FormMSG(262, 'UPM') }}
												</b-form-checkbox>
												<b-form-checkbox
													size="sm"
													class="mb-3"
													v-model="data.item.lineProducer"
													@change="handleAccessLevel(lineProducer, $event, data.item, screenAccess)"
												>
													{{ FormMSG(263, 'Line Producer') }}
												</b-form-checkbox>
												<b-form-checkbox
													size="sm"
													class="mb-3"
													v-model="data.item.studio"
													@change="manageStudioChange($event, data.item)"
												>
													{{ FormMSG(265, 'Studio') }}
												</b-form-checkbox>
											</b-col>
										</b-row>
									</template>
									<template slot="actions" slot-scope="data">
										<b-button
											variant="success"
											size="sm"
											@click="sendEmail(data.item)"
											class="mr-1 btn bg-transparent border-0"
											title="Send email"
										>
											<component :is="getLucideIcon(ICONS.MAIL.name)" :color="ICONS.MAIL.color" :size="20" />
										</b-button>
										<b-button
											variant="success"
											:disabled="data.item.phone.length < 1"
											size="sm"
											@click="callPhone(data.item)"
											class="mr-1 btn bg-transparent border-0"
											title="Call by phone"
										>
											<component :is="getLucideIcon(ICONS.PHONE.name)" :color="ICONS.PHONE.color" :size="20" />
										</b-button>
										<b-button
											variant="success"
											:disabled="data.item.picture.length < 1"
											size="sm"
											class="btn bg-transparent border-0"
											@click="showPicture(data.item.picture)"
											title="View picture"
										>
											<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
										</b-button>
										<b-button
											class="mr-1 btn bg-transparent border-0"
											variant="success"
											size="sm"
											@click="downloadInfosheet(data.item.id)"
											title="Download infosheet"
										>
											<component :is="getLucideIcon(ICONS.EYE.name)" color="#225CBD" :size="20" />
										</b-button>
										<b-button
											variant="primary"
											size="sm"
											@click="rowClicked(data.item)"
											title="Update user"
											class="btn bg-transparent border-0"
										>
											<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" />
										</b-button>

										<b-button
											size="sm"
											@click="eraseUser(data.item)"
											:disabled="isDisabled(data)"
											title="Delete user"
											class="btn bg-transparent border-0"
										>
											<delete color="tomato" :size="20" />
										</b-button>
									</template>
								</CardListBuilder>
								<b-table
									v-if="$screen.width >= 992"
									:hover="hover"
									responsive
									selectable
									:selected-variant="selectedColor"
									:select-mode="selectMode"
									:items="Users"
									sort-by="name"
									:fields="fields"
									:current-page="currentPage"
									:per-page="perPage"
									sticky-header="700px"
									@row-clicked="rowClicked"
									:head-variant="hv"
									bordered
									small
									ref="table-users"
									:busy="isBusy"
									id="containerUsers"
									:empty-text="FormMSG(250, 'No data found')"
									show-empty
								>
									<template #table-busy>
										<div class="d-flex justify-content-center align-items-center">
											<b-spinner class="align-middle" small></b-spinner>
											<strong class="ml-2">{{ FormMSG(353, 'Loading') }}...</strong>
										</div>
									</template>
									<template v-slot:cell(functionName)="data">
										{{ data.item.customFunctionName ? data.item.customFunctionName : data.item.functionName }}
									</template>
									<!-- <template slot="pict" slot-scope="data"> -->
									<template v-slot:cell(pict)="data">
										<b-dropdown
											no-caret
											dropleft
											offset="25"
											variant="none"
											style="border-right: 0px !important; padding-top: 0px !important"
											boundary="window"
										>
											<template #button-content>
												<more-vertical />
											</template>
											<b-dropdown-item-button @click="sendEmail(data.item)"
												><component :is="getLucideIcon(ICONS.MAIL.name)" :color="ICONS.MAIL.color" :size="20" /> &nbsp;
												<b>{{ FormMSG(2000, 'Send email') }}</b></b-dropdown-item-button
											>
											<b-dropdown-item-button :disabled="data.item.phone.length < 1" @click="callPhone(data.item)"
												><component :is="getLucideIcon(ICONS.PHONE.name)" :color="ICONS.PHONE.color" :size="20" /> &nbsp;
												<b>{{ FormMSG(2001, 'Call by phone') }}</b></b-dropdown-item-button
											>
											<b-dropdown-item-button :disabled="data.item.picture.length < 1" @click="showPicture(data.item.picture)"
												><component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" /> &nbsp;
												<b>{{ FormMSG(2002, 'View picture') }}</b></b-dropdown-item-button
											>
											<b-dropdown-item-button @click="downloadInfosheet(data.item.id)"
												><component :is="getLucideIcon('Download')" color="#225CBD" :size="20" /> &nbsp;
												<b>{{ FormMSG(2003, 'Download infosheet') }}</b></b-dropdown-item-button
											>
											<b-dropdown-item-button>
												<div class="d-flex flex-row align-items-center w-100 gap-2">
													<div class="d-flex flex-row align-items-center flex-grow-1">
														<b-button
															variant="light"
															class="custom-button-user"
															:disabled="isDisabled(data)"
															@click="eraseUser(data.item)"
														>
															<delete color="tomato" /> &nbsp; <b>{{ FormMSG(2004, 'Delete user') }}</b>
														</b-button>
													</div>
													<div
														v-if="isDisabled(data)"
														v-b-tooltip.hover.bottom.html="getInformationDisabled()"
														class="d-flex flex-row align-items-center flex-grow-1"
													>
														<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="22" />
													</div>
												</div>
											</b-dropdown-item-button>
										</b-dropdown>
									</template>
									<!-- slot-scope="data"  -->
									<template slot="HEAD_isProd"> Prod Rights </template>
									<template v-slot:cell(Impersonate)="data">
										<b-button class="btn bg-transparent border-0" variant="primary" size="sm" @click="becomeUser(data.item)">
											<component :is="getLucideIcon(ICONS.EYE.name)" color="#225CBD" :size="20" />
										</b-button>
									</template>
									<template v-slot:cell(delUser)="data">
										<b-button v-if="myUserId != data.item.id" variant="none" size="sm" @click="deleteUser(data.item)">
											<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" />
										</b-button>
									</template>
									<template v-slot:cell(isDev)="data">
										<b-form-checkbox size="lg" v-model="data.item.isDev" @change="manageIsDevChange($event, data.item)"> </b-form-checkbox>
									</template>
									<template v-slot:cell(isTranslator)="data">
										<b-form-checkbox size="lg" v-model="data.item.isTranslator" @change="manageIsTranslatorChange($event, data.item)">
										</b-form-checkbox>
									</template>
									<template v-slot:cell(validationLevel)="data">
										<b-form-select
											v-model="data.item.validationLevel"
											:options="optionsForValidationLevel"
											@change="manageValidationChange(data.item)"
											id="valLevel"
											size="md"
										>
										</b-form-select>
									</template>
									<!-- slot-scope="data" -->
									<template slot="HEAD_isAdmin"> Admin Rights </template>
									<!-- <template slot="info" slot-scope="data"> -->
									<template v-slot:cell(info)="data">
										<a href="#" @click.prevent="rowClicked(data.item)">
											<component :is="getLucideIcon(ICONS.INFO.name)" :color="ICONS.INFO.color" :size="20" />
										</a>
									</template>
									<template v-slot:cell(permissions)="data">
										<div class="d-flex justify-content-center" style="min-width: 60px">
											<b-dropdown
												id="dropdown-offer-action"
												size="sm"
												:text="FormMSG(249, 'Permissions')"
												variant="warning"
												dropleft
												boundary="window"
												:class="lengthUsers <= 8 ? 'position-absolute' : ''"
											>
												<b-dropdown-item
													v-if="isForProject"
													@click="handleAccessLevel('canManageCrewList', !data.item.canManageCrewList, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageCrewList">
														{{ FormMSG(2970, 'Manage crew list') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useDoc"
													@click="handleAccessLevel('canManageDocuments', !data.item.canManageDocuments, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageDocuments">
														{{ FormMSG(2971, 'Manage documents') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useDoc"
													@click="
														handleAccessLevel(
															'canManageDocumentPackage',
															!data.item.canManageDocumentPackage,
															data.item,
															screenAccess
														)
													"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageDocumentPackage">
														{{ FormMSG(2440, 'Manage document package') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useCallSheet"
													@click="handleAccessLevel('canManageCallSheets', !data.item.canManageCallSheets, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageCallSheets">
														{{ FormMSG(2450, 'Manage call sheets') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useAcc"
													@click="
														handleAccessLevel(
															'canManageAccommodations',
															!data.item.canManageAccommodations,
															data.item,
															screenAccess
														)
													"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageAccommodations">
														{{ FormMSG(2973, 'Manage accomodations') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useLoc"
													@click="handleAccessLevel('canManageLocations', !data.item.canManageLocations, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageLocations">
														{{ FormMSG(2449, 'Manage locations') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useTrans"
													@click="handleAccessLevel('canManageTransport', !data.item.canManageTransport, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageTransport">
														{{ FormMSG(24049, 'Manage transport') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useImportExport"
													@click="handleAccessLevel('canExportImport', !data.item.canExportImport, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canExportImport">
														{{ FormMSG(2455, 'Can import/export') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useBudget"
													@click="
														handleAccessLevel(
															'canViewDepartmentBudget',
															!data.item.canViewDepartmentBudget,
															data.item,
															screenAccess
														)
													"
												>
													<b-form-checkbox size="sm" v-model="data.item.canViewDepartmentBudget">
														{{ FormMSG(2690, 'View department budget') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useBudget"
													@click="handleAccessLevel('canViewGlobalBudget', !data.item.canViewGlobalBudget, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canViewGlobalBudget">
														{{ FormMSG(24430, 'View global budget') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useGreenTable"
													@click="handleAccessLevel('canViewGreenTable', !data.item.canViewGreenTable, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canViewGreenTable">
														{{ FormMSG(244030, 'View green table') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useReporting"
													@click="
														handleAccessLevel(
															'canAccessGreenReporting',
															!data.item.canAccessGreenReporting,
															data.item,
															screenAccess
														)
													"
												>
													<b-form-checkbox size="sm" v-model="data.item.canAccessGreenReporting">
														{{ FormMSG(24560, 'View Green reporting screens') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useCarbonRemoval"
													@click="
														handleAccessLevel('canManageCarbonRemoval', !data.item.canManageCarbonRemoval, data.item, screenAccess)
													"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageCarbonRemoval">
														{{ FormMSG(24480, 'Manage carbon removal') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useWaste"
													@click="handleAccessLevel('canViewWasteScreens', !data.item.canViewWasteScreens, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canViewWasteScreens">
														{{ FormMSG(24481, 'View waste screens') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject && useTipOfDay"
													@click="handleAccessLevel('canManageTipOfTheDay', !data.item.canManageTipOfTheDay, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canManageTipOfTheDay">
														{{ FormMSG(24405, 'Manage tip of the day') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject"
													@click="handleAccessLevel('canAccessConfig', !data.item.canAccessConfig, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canAccessConfig">
														{{ FormMSG(246, 'Can access config') }}
													</b-form-checkbox>
												</b-dropdown-item>
											</b-dropdown>
										</div>
									</template>
									<template v-slot:cell(role)="data">
										<div class="d-flex justify-content-center" style="min-width: 60px">
											<b-dropdown
												id="dropdown-offer-action"
												size="sm"
												:text="FormMSG(2490, 'Roles')"
												variant="warning"
												dropleft
												boundary="window"
												:class="lengthUsers <= 8 ? 'position-absolute' : ''"
											>
												<b-dropdown-item
													v-if="isForProject"
													@click="handleAccessLevel(gateKeeper, !data.item.gateKeeper, data.item, isRole)"
												>
													<b-form-checkbox size="sm" v-model="data.item.gateKeeper">
														{{ FormMSG(266, 'Gate Keeper') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject"
													@click="handleAccessLevel(productionAccountant, !data.item.productionAccountant, data.item, isRole)"
												>
													<b-form-checkbox size="sm" v-model="data.item.productionAccountant">
														{{ FormMSG(261, 'Production Account') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item v-if="isForProject" @click="handleAccessLevel(upm, !data.item.upm, data.item, isRole)">
													<b-form-checkbox size="sm" v-model="data.item.upm">
														{{ FormMSG(262, 'UPM') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject"
													@click="handleAccessLevel(lineProducer, !data.item.lineProducer, data.item, isRole)"
												>
													<b-form-checkbox size="sm" v-model="data.item.lineProducer">
														{{ FormMSG(263, 'Line Producer') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item v-if="isForProject" @click="handleAccessLevel(studio, !data.item.studio, data.item, isRole)">
													<b-form-checkbox size="sm" v-model="data.item.studio">
														{{ FormMSG(265, 'Studio') }}
													</b-form-checkbox>
												</b-dropdown-item>
												<b-dropdown-item
													v-if="isForProject"
													@click="handleAccessLevel(canValidateI9, !data.item.canValidateI9, data.item, screenAccess)"
												>
													<b-form-checkbox size="sm" v-model="data.item.canValidateI9">{{
														FormMSG(68, 'Can validate I-9')
													}}</b-form-checkbox>
												</b-dropdown-item>
											</b-dropdown>
										</div>
									</template>
								</b-table>
								<b-row>
									<b-col cols="12">
										<div class="nb-text">
											{{ FormMSG(300, 'Totals:') }} {{ globalUsers.length }} {{ FormMSG(301, 'of') }} {{ totalOfUsers }}
											{{ FormMSG(302, 'records') }}
										</div>
									</b-col>
								</b-row>
								<b-modal
									header-class="header-class-modal-doc-package"
									v-model="showUserModal"
									@cancel="okAfterUserEdit"
									@ok="dispatchEvent"
									:cancel-title="FormMSG(351, 'Close')"
									:ok-title="FormMSG(352, 'New contract')"
									cancel-variant="light"
									:title="selectedUserId > 0 ? FormMSG(10, 'User') : FormMSG(11, 'Crew member invitation')"
									size="xxl"
									centered
									no-close-on-backdrop
									modal-class="mui-animation"
									:fade="false"
								>
									<user
										ref="new-contract"
										:selectedUserData="selectedUserData"
										:userId="selectedUserId"
										:display-add-btn="false"
										v-on:closeForm="toggleUserModal"
										v-if="selectedUserId"
										:handleAccessLevel="(item, value, data, screenAccess) => handleAccessLevel(item, value, data, screenAccess)"
									/>
									<user-form :projectId="projectId" v-on:closeForm="toggleUserModal" @user-form:success="refreshData" v-else />
								</b-modal>
								<b-modal
									header-class="header-class-modal-doc-package"
									:title="FormMSG(62, 'Delete User?')"
									class="modal-success"
									v-model="successDeleteUserModal"
									@ok="confirmedDeleteUser()"
									ok-variant="primary"
									:ok-title="this.FormMSG(63, 'Delete user')"
									modal-class="mui-animation"
									:fade="false"
								>
									<p>{{ this.emailOfUserToDelete }}</p>
								</b-modal>
								<b-modal
									header-class="header-class-modal-doc-package"
									:title="FormMSG(20, 'Picture')"
									class="modal-success"
									v-model="showContactPicture"
									@ok="showContactPicture = false"
									ok-variant="success"
									ok-only
									modal-class="mui-animation"
									:fade="false"
								>
									<b-card v-for="(oneImage, i) in this.contactImagePath" :key="i">
										<b-container fluid class="p-4 bg-dark">
											<b-row>
												<b-col>
													<b-img fluid :src="oneImage"></b-img>
												</b-col>
											</b-row>
										</b-container>
									</b-card>
								</b-modal>
								<script-export-modal
									v-model="isScriptInfosheetOpen"
									:items="scripts"
									:custom="customReport"
									export-type="user"
									@script-export-modal:loading="handleLoading"
									@script-export-modal:closed="userRerpotOnClosed"
								/>
							</b-card>
						</b-tab>
						<b-tab v-if="canSeeUserSetting && !isForProjects && !isForGreenshotUser" lazy :title="FormMSG(1230, 'Onboarding')">
							<Views />
						</b-tab>
						<b-tab lazy v-if="canSeeDocPackage && !isForProjects && !isForGreenshotUser && useDoc" :title="FormMSG(1200, 'Document Package')">
							<DocumentPackageIndex />
						</b-tab>
					</b-tabs>
				</transition>
			</div>
		</div>
	</errorContainer>
</template>

<script>
import { HttpValidation } from '@/helpers/HttpValidation';
import { Switch as cSwitch } from '@coreui/vue';
import { store } from '@/store';
import { getObjectFromValue, isNil } from '~utils';
import globalMixin from '@/mixins/global.mixin';
import User from './User';
import UserForm from './UserForm';
import languageMessages from '@/mixins/languageMessages';
import { getParamVersion } from '@/shared/version';
import axios from 'axios';
import { Search, X, ChevronLeft, MoreVertical, Trash2 as Delete } from 'lucide-vue';
import { ACCESS_LEVEL } from '@/utils/utils';
import DeterMixin from '@/mixins/deter.mixin';
import {
	getUsers,
	addProd,
	delProd,
	addAdmin,
	delAdmin,
	addDev,
	delDev,
	addTranslator,
	delTranslator,
	signinAs,
	delUser,
	delUserRequest,
	delUserFromProject,
	delUserValidation,
	validate,
	canAssign,
	addUpdUpm,
	addUpdStudio,
	addUpdUserProjectAccessLevel,
	getCountTotalOfUser
} from '@/cruds/users.crud';
import { getProject } from '@/cruds/project.crud';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { getScripts } from '@/cruds/script.crud';
import Views from '../../modules/onboarding/views/index.vue';
import DocumentPackageIndex from '../../modules/document-package/views/index.vue';
import ValidateDocuments from '../../modules/onboarding/views/ValidateDocuments.vue';
import { userReport } from '@/cruds/export.crud';
import _ from 'lodash';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT } from '@/shared/constants';
import { getFileExtension } from '@/shared/helpers';
import filterInputSearch from './filterInput.vue';

export default {
	name: 'Users',
	mixins: [languageMessages, globalMixin, DeterMixin],
	components: {
		cSwitch,
		User,
		UserForm,
		ChevronLeft,
		Search,
		X,
		ScriptExportModal,
		Views,
		DocumentPackageIndex,
		ValidateDocuments,
		MoreVertical,
		Delete,
		filterInputSearch
	},
	props: {
		hover: {
			type: Boolean,
			default: true
		},
		striped: {
			type: Boolean,
			default: true
		},
		bordered: {
			type: Boolean,
			default: false
		},
		small: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		},
		acceptFiles: {
			type: String,
			required: false,
			default: '.xlsx'
		},
		apiUploadUrl: {
			type: String,
			required: false,
			default: 'uploadImportFormatFile'
		},
		formFilename: {
			type: String,
			required: false,
			default: 'importFile'
		}
	},
	watch: {
		indexOfTab: {
			async handler(newVal) {
				setTimeout(async () => {
					await new Promise((resolve) => {
						resolve(this.$refs['table-users']);
					})
						.then((response) => {
							if (response) {
								const element = response.$el;
								element.removeEventListener('scroll', this.handleScroll);
								element.addEventListener('scroll', this.handleScroll);
								return true;
							}
							return false;
						})
						.catch(() => {
							return false;
						});
				}, 200);
				return newVal;
			},
			immediate: true,
			deep: true
		},
		filter: {
			handler(newVal) {
				if (newVal.length === 0) {
					this.keyword = null;
					this.Users = this.globalUsers;
				}
				return newVal;
			},
			immediate: true,
			deep: true
		}
	},
	mounted() {
		this.$nextTick(async () => {
			this.initilizeAccess();
			this.$root.$on('bv::modal::change', (isVisible, modalId) => {
				this.toggleUserModal();
			});
			this.reloadData();
			this.generateScroll();
			setTimeout(() => this.useFimalac(), 100);
		});
	},
	data: () => {
		let limited = Math.round(window.innerHeight / ((window.innerHeight * 5) / 100));
		const {
			isAdmin,
			isProd,
			isVip,
			canAssign,
			canAccessUsers,
			canAccessBudget,
			canAccessMyBudget,
			canAccessGreenReporting,
			canAccessConfig,
			canAccessOnboarding,
			canAccessCrewList,
			canValidateI9,
			gateKeeper,
			productionAccountant,
			upm,
			lineProducer,
			studio,
			canEditExpense,
			canEditPo,
			screenAccess,
			canLockBudget,
			canModifyEstimatedFinalCost,
			canEditAndAddCode,
			isRole,
			delValidationRole,
			addUpdValidationRole
		} = ACCESS_LEVEL;
		return {
			currentValidationLevel: 0,
			hv: 'dark',
			isLoading: false,
			isAdmin,
			isProd,
			isVip,
			canAssign,
			canAccessUsers,
			canAccessBudget,
			canAccessMyBudget,
			canAccessGreenReporting,
			canAccessConfig,
			canAccessOnboarding,
			canAccessCrewList,
			canValidateI9,
			gateKeeper,
			productionAccountant,
			upm,
			lineProducer,
			studio,
			canEditExpense,
			canEditPo,
			canLockBudget,
			canModifyEstimatedFinalCost,
			canEditAndAddCode,
			screenAccess,
			isRole,
			delValidationRole,
			addUpdValidationRole,
			erreur: {},
			Users: [],
			currentPage: 1,
			perPage: 0,
			selectedUserId: null,
			showUserModal: false,
			successDeleteUserModal: false,
			idOfUserToDelete: 0,
			selectedUserData: {},
			emailOfUserToDelete: '',
			projectId: 0,
			filter: '',
			contactImagePath: [],
			showContactPicture: false,
			myUserId: 0,
			project: null,
			selectMode: 'single',
			selectedColor: 'primary',
			isScriptInfosheetOpen: false,
			infosheetTypeId: 7,
			scripts: [],
			ACCESS_LEVEL: {},
			isBusy: false,
			didUpdateRole: false,
			customReport: null,
			isLoadingDownload: false,
			indexOfTab: 0,
			page: limited,
			initPage: limited,
			isMore: false,
			limit: limited,
			offset: 0,
			keyword: null,
			globalUsers: [],
			totalOfUsers: 0,
			statusNotifications: false,
			scrollDefault: 0
		};
	},
	beforeDestroy() {
		const elementTable = this.$refs['table-users'];
		if (elementTable && elementTable.$el) {
			elementTable.$el.removeEventListener('scroll', this.onScroll);
		}
	},
	methods: {
		generateScroll() {
			const elementTable = this.$refs['table-users'];
			if (elementTable && elementTable.$el) {
				elementTable.$el.addEventListener('scroll', this.onScroll);
			}
		},
		onScroll(event) {
			event.preventDefault();
			const { scrollTop } = event.target;
			this.scrollDefault = scrollTop;
		},
		onScrollAuto(fromTop) {
			const elementTable = this.$refs['table-users'];
			if (elementTable && elementTable.$el) {
				elementTable.$el.scrollTo({ top: fromTop, behavior: 'smooth' });
			}
		},
		getInformationDisabled() {
			return this.FormMSG(201, 'It is not possible to delete this user. Data has been entered into or salary grids.');
		},
		useFimalac() {
			const license = store.state.myProject.license !== undefined ? store.state.myProject.license.license : '';
			const isFimalacLicense = /fimalac/gi.test(license);
			const status = HttpValidation.status && isFimalacLicense;
			this.statusNotifications = status;
		},
		onHandleInitFilter() {
			this.filter = '';
			this.keyword = null;
		},
		isDisabled(data) {
			return data.item.id == store.state.myProfile.id || data.item.cannotBeDeleted;
		},
		initilizeAccess() {
			this.ACCESS_LEVEL = ACCESS_LEVEL;
		},
		async filteredUsers(text) {
			this.filter = text;
			let keyWord = this.filter.toString().replaceAll(/\s/gi, '%');
			this.keyword = keyWord;
			let myVariables = {};
			if (this.keyword.length !== 0) {
				await this.$store.dispatch('refreshToken');
				const projNumber = parseInt(this.$route.params.id, 10);
				if (this.isFromProject) {
					if (projNumber == 0) {
						myVariables = {
							myProject: true,
							limit: null,
							offset: null,
							keyword: this.keyword
						};
					} else {
						this.project = await getProject(projNumber);
						myVariables = {
							projectId: projNumber,
							limit: null,
							offset: null,
							keyword: this.keyword
						};
					}
				} else if (this.isForProject) {
					myVariables = {
						myProject: true,
						limit: null,
						offset: null,
						keyword: this.keyword
					};
				} else {
					myVariables = {
						...myVariables,
						myProject: false,
						limit: null,
						offset: null,
						keyword: this.keyword
					};
				}
				await getUsers(myVariables, projNumber)
					.then((records) => {
						this.Users = [...records];
					})
					.catch((error) => {
						this.erreur = error;
					});
			}
		},
		async handleScroll(event) {
			if (this.globalUsers.length < this.totalOfUsers) {
				if (event.target.scrollTop) {
					if (Math.ceil(event.target.scrollTop + event.target.clientHeight + 2) >= event.target.scrollHeight) {
						if (!this.isMore) {
							if (event.target.scrollLeft) {
								this.isMore = false;
							} else {
								this.isMore = true;
							}
							this.offset += 1;
							setTimeout(async () => {
								await this.showLoader(this.handleGetUsers, 'containerUsers');
								this.isMore = false;
							}, 200);
						}
					}
				}
			}
		},
		onLoadMore() {
			if (this.filter.length !== 0) {
				this.isMore = false;
			} else {
				if (this.Users.length <= 10) {
					this.isMore = false;
				} else {
					this.isMore = true;
					setTimeout(() => {
						this.page += this.initPage;
						this.isMore = false;
					}, [200]);
				}
			}
		},
		async manageUpmChange(value, item, showToast = true) {
			try {
				await addUpdUpm({
					userId: item.id,
					Upm: value
				});

				item.upm = value;
				if (showToast) {
					this.createToastForSuccess();
				}
			} catch (error) {
				this.erreur = error;
			}
		},
		async manageStudioChange(value, item, showToast = true) {
			try {
				await addUpdStudio({
					userId: item.id,
					Studio: value
				});

				item.studio = value;
				if (showToast) {
					this.createToastForSuccess();
				}
			} catch (error) {
				this.erreur = error;
			}
		},
		/**
		 * @param {String} key
		 * @return {String}
		 */
		async handleGetUsers() {
			let myVariables = {};
			await this.$store.dispatch('refreshToken');
			const projNumber = parseInt(this.$route.params.id, 10);
			if (this.isFromProject) {
				if (projNumber == 0) {
					myVariables = {
						myProject: true,
						limit: this.limit,
						offset: this.offset,
						keyword: this.keyword
					};
				} else {
					this.project = await getProject(projNumber);
					myVariables = {
						projectId: projNumber,
						limit: this.limit,
						offset: this.offset,
						keyword: this.keyword
					};
				}
			} else if (this.isForProject) {
				myVariables = {
					myProject: true,
					limit: this.limit,
					offset: this.offset,
					keyword: this.keyword
				};
			} else {
				myVariables = {
					...myVariables,
					myProject: null,
					limit: this.limit,
					offset: this.offset,
					keyword: this.keyword
				};
			}
			await getUsers(myVariables, projNumber)
				.then((records) => {
					this.Users = [...this.Users, ...records];
					if (this.filter === null || this.filter.length === 0) {
						this.globalUsers = this.Users;
					}
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async reloadData() {
			let myVariables = {};
			await this.$store.dispatch('refreshToken');
			const projNumber = parseInt(this.$route.params.id, 10);
			if (this.isFromProject) {
				if (projNumber == 0) {
					// current project
					myVariables = {
						myProject: true,
						limit: this.limit,
						offset: this.offset,
						keyword: this.keyword
					};
				} else {
					this.project = await getProject(projNumber);
					myVariables = {
						projectId: projNumber,
						limit: this.limit,
						offset: this.offset,
						keyword: null
					};
				}
			} else if (this.isForProject) {
				myVariables = {
					myProject: true,
					limit: this.limit,
					offset: this.offset,
					keyword: this.keyword
				};
			} else {
				myVariables = {
					...myVariables,
					myProject: false,
					limit: this.limit,
					offset: this.offset,
					keyword: this.keyword
				};
			}
			this.isBusy = true;
			this.totalOfUsers = await getCountTotalOfUser(myVariables.myProject, null);
			const optionsFilter = [...Array(myVariables.offset + 1)].map((_, index) => ({ ...myVariables, offset: index }));
			const responses = await Promise.all(
				optionsFilter.map((option) => {
					return getUsers(option, projNumber);
				})
			)
				.then((records) => {
					const newRecords = records.flat();
					this.Users = newRecords;
					if (this.keyword === null || this.keyword.length === 0) {
						this.globalUsers = newRecords;
					}
					const { id } = store.state.myProfile;
					this.myUserId = parseInt(id);
				})
				.catch((error) => {
					this.erreur = error;
				})
				.finally(() => {
					this.isBusy = false;
				});
			let userRequestsVariable = { projectId: 0 };
			if (this.isFromProject) {
				userRequestsVariable.projectId = parseInt(this.$route.params.id, 10);
			}
			return responses;
		},
		getFieldLabel(key) {
			const item = getObjectFromValue(this.fields, 'key', key);
			return isNil(item) ? '' : item.label;
		},
		sendEmail(item) {
			var email = item.email;
			var subject = this.FormMSG(45, 'Subject');
			var emailBody =
				this.FormMSG(46, 'Hello') + ' ' + item.firstName + ',%0D%0A%0D%0A' + store.state.myProfile.firstName + ' ' + store.state.myProfile.name;
			document.location = 'mailto:' + email + '?body=' + emailBody + '&subject=' + subject;
		},
		callPhone(item) {
			window.location.href = 'tel://' + item.phone;
		},
		async downloadInfosheet(id) {
			id = +id;
			this.isLoadingDownload = true;
			this.warning = '';
			this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.infosheetTypeId, true);
			if (this.scripts.length === 0) {
				await this.manageInfosheet(id).finally(() => {
					this.isLoadingDownload = false;
				});
			} else if (this.scripts.length === 1) {
				await this.manageInfosheet(id, {
					id: this.scripts[0].id,
					fileName: this.scripts[0].customExportFileName,
					ext: this.getExtensionScript(
						OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
						this.scripts[0].customExtension,
						getFileExtension(this.scripts[0].fileName)
					)
				}).finally(() => {
					this.isLoadingDownload = false;
				});
			} else {
				this.isScriptInfosheetOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: false
				};
			}
		},
		showPicture(image) {
			// build path for picture
			this.contactImagePath = [];

			var fullPath = process.env.VUE_APP_GQL + '/images/' + image;
			this.contactImagePath.push(fullPath);
			this.showContactPicture = true;
		},
		goBack() {
			this.$router.go(-1);
		},
		deleteUser: function (item) {
			this.idOfUserToDelete = item.id;
			this.emailOfUserToDelete = item.email;
			this.successDeleteUserModal = true;
		},
		async deleteUserRequest(item) {
			this.$bvModal
				.msgBoxConfirm(this.FormMSG(64, 'Are you sure to delete this crew member invitation ?'), {
					title: this.FormMSG(65, 'Confirm'),
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.FormMSG(66, 'YES'),
					cancelTitle: this.FormMSG(67, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;

					const userRequestId = parseInt(item.id, 10);
					await delUserRequest(userRequestId)
						.then(async (result) => {
							// force refresh
							this.reloadData();
						})
						.catch((error) => {
							this.erreur = error;
						});
				})
				.catch((err) => console.log(err));
		},
		async confirmedDeleteUser() {
			const userId = parseInt(this.idOfUserToDelete, 10);
			await delUser(userId)
				.then((result) => {
					// force refresh
					window.location.reload(true);
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		eraseUser(user) {
			const action2 = async () => {
				await delUserFromProject(user.id).then((res) => {
					if (res) {
						this.$toast({
							message: this.FormMSG(26540, 'User deleted successfully'),
							title: 'Success',
							variant: 'success'
						});
						this.Users = this.Users.filter((u) => u.id !== user.id);
					} else {
						this.$toast({
							message: this.FormMSG(26545, 'There was a problem while trying to delete the user'),
							title: 'Warning',
							variant: 'warning'
						});
					}
				});
			};
			const action = async () => {
				this.confirmModal(
					action2,
					`${
						this.FormMSG(87445, 'Are you sure you want to delete the user') +
						' ' +
						user.fullName +
						'? ' +
						this.FormMSG(87490, 'This will erase all of the user associated data from the project')
					}`
				);
			};
			this.confirmModal(action, `${this.FormMSG(87410, 'Do you want to delete the user') + ' ' + user.fullName}?`);
		},
		async okAfterUserEdit() {
			const fromTop = this.scrollDefault;
			const response = this.reloadData();
			return new Promise((resolve, _) => {
				resolve(response);
			}).then((res) => {
				this.onScrollAuto(fromTop);
			});
		},
		async becomeUser(item) {
			const userId = parseInt(item.id, 10);
			await signinAs(userId)
				.then(async (result) => {
					await store.onNewToken(this.$apollo.provider.defaultClient, result);
					this.$router.go(0);
					this.$router.push('/my-dashboard' + getParamVersion()).catch(() => {});
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		toggleUserModal: function () {
			if (this.showUserModal) {
				this.selectedUserId = null;
			}
			this.showUserModal = !this.showUserModal;
		},
		selectUserId: function (userId) {
			this.selectedUserId = userId;
		},
		async addOrUpdateValidation(userId, departmentNumber, levelTs, levelPo, levelExp) {
			const replace = true;
			const projectId = parseInt(store.projectID(), 10);
			await validate({
				userId,
				departmentNumber,
				levelTs,
				levelPo,
				levelExp,
				projectId,
				replace
			})
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},

		getRowCount(items) {
			return items.length;
		},
		userLink(id) {
			if (this.isForProject) {
				return `/project/users/${id.toString()}`;
			} else {
				return `/users/${id.toString()}`;
			}
		},
		rowClicked(item) {
			this.selectedUserData = Object.assign({}, item);
			const userId = item.id.toString();
			this.selectUserId(userId);
			this.toggleUserModal();
		},
		newUser() {
			this.selectedUserId = null;
			if (this.isFromProject) {
				this.projectId = parseInt(this.$route.params.id, 10);
			}
			this.toggleUserModal();
		},
		rowClass(item, type) {
			if (!item) return;
			if (item.isDev && store.isDev()) return 'table-warning'; // store.isDev() : les "spectateurs" non isDev ne doivent pas savoir qui est dev.
			if (item.isProd) return 'table-primary'; // quand isProd regarde les users du projet
			if (item.isAdmin) return 'table-success';
		},
		async makeProd(id) {
			const userId = parseInt(id, 10);
			var myVariables = {};
			if (this.isFromProject) {
				const projectId = parseInt(this.$route.params.id, 10);
				myVariables = {
					userId,
					projectId
				};
			} else {
				myVariables = {
					userId
				};
			}
			await addProd(myVariables)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async handleAccessLevel(accessname, value, item, ...args) {
			function setAccess(keyName, value) {
				const obj = {};
				obj[keyName] = value;
				return obj;
			}
			let response = false;
			if (args[0] == this.isRole) {
				item[accessname] = value;
				if (+item.id == store.userID()) {
					store.state.myProfile[accessname] = value;
				}
				if (Object.values(setAccess(accessname, value))[0] === false) {
					let payload = {
						ValidationId: 0,
						UserId: +item.id,
						ProjectId: +store.state.myProject.id,
						ValLvlDoc:
							accessname === this.gateKeeper
								? 5
								: accessname === this.productionAccountant
								? 6
								: accessname === this.upm
								? 7
								: accessname === this.lineProducer
								? 8
								: 9
					};
					response = await addUpdUserProjectAccessLevel(payload, this.isRole, this.delValidationRole);
				} else {
					let payload = {
						ValidationId: 0,
						UserId: +item.id,
						ProjectId: +store.state.myProject.id,
						UpdValidation: {
							department: -1,
							type: 1,
							validationLevelDoc:
								accessname === this.gateKeeper
									? 5
									: accessname === this.productionAccountant
									? 6
									: accessname === this.upm
									? 7
									: accessname === this.lineProducer
									? 8
									: 9
						}
					};
					response = await addUpdUserProjectAccessLevel(payload, this.isRole, this.addUpdValidationRole);
				}
			}
			if (args[0] === this.screenAccess) {
				item[accessname] = value;
				let payload = {
					userId: +item.id,
					projectId: +store.state.myProject.id,
					...setAccess(accessname, value)
				};
				response = await addUpdUserProjectAccessLevel(payload, this.screenAccess);
			}

			item[accessname] = value;
			if (+item.id === store.userID()) {
				await this.$store.dispatch('refreshToken');
			}
			return response && this.createToastForSuccess();
		},
		manageIsDevChange(value, item) {
			if (value) {
				this.makeIsDev(item.id);
			} else {
				this.delIsDev(item.id);
			}
			item.isDev = value;
			this.createToastForSuccess();
		},
		manageIsTranslatorChange(value, item) {
			if (value) {
				this.makeIsTranslator(item.id);
			} else {
				this.delIsTranslator(item.id);
			}

			item.isTranslator = value;
			this.createToastForSuccess();
		},
		async manageValidationChange(item) {
			if (item.validationLevel === 0) {
				await delUserValidation(item.id);
			} else {
				this.addOrUpdateValidation(
					parseInt(item.id),
					parseInt(item.department),
					parseInt(item.validationLevel),
					parseInt(item.validationLevel),
					parseInt(item.validationLevel)
				);
			}
		},
		async delProd(id) {
			const userId = parseInt(id, 10);
			var myVariables = {};
			if (this.isFromProject) {
				const projectId = parseInt(this.$route.params.id, 10);
				myVariables = {
					userId,
					projectId
				};
			} else {
				myVariables = {
					userId
				};
			}
			await delProd(myVariables)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async makeAdmin(id) {
			const userId = parseInt(id, 10);
			await addAdmin(userId)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async delAdmin(id) {
			const userId = parseInt(id, 10);
			await delAdmin(userId)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async makeIsDev(id) {
			const userId = parseInt(id, 10);
			await addDev(userId)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async delIsDev(id) {
			const userId = parseInt(id, 10);
			await delDev(userId)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async makeIsTranslator(id) {
			const userId = parseInt(id, 10);
			await addTranslator(userId)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		async delIsTranslator(id) {
			const userId = parseInt(id, 10);
			await delTranslator(userId)
				.then((result) => {})
				.catch((error) => {
					this.erreur = error;
				});
		},
		importXls(e) {
			this.isLoading = true;
			const file = e.target.files[0];
			let formData = new FormData();
			formData.append(this.formFilename, file);
			formData.append('fileName', file.name);
			formData.append('fileType', file.type);
			formData.append('importFormatType', 0);
			const urlStr = process.env.VUE_APP_GQL + '/' + this.apiUploadUrl;
			axios
				.post(urlStr, formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(async (record) => {
					// console.log('data', record);
					this.reloadData();
				})
				.catch((e) => {
					console.error({ e });
				})
				.finally(() => {
					this.isLoading = false;
				});
		},
		createToastForSuccess() {
			this.$toast({
				message: this.FormMSG(260, 'Update permissions successfully'),
				title: 'Success',
				variant: 'success'
			});
		},
		async refreshData() {
			this.reloadData();
			this.showUserModal = false;
		},
		dispatchEvent(e) {
			e.preventDefault();
			if (!isNil(this.$refs['new-contract'])) {
				this.$refs['new-contract'].$refs['new-contract'].click();
			}
		},
		async manageInfosheet(subjectId, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_USER, script.id, script.fileName);

			await userReport(
				{
					subjectId,
					sendEmail
				},
				script.id
			)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.' + script.ext);
					}
				})
				.catch((e) => {
					console.error(e);
				});
		},
		handleLoading(payload) {
			if (payload) {
				this.isLoadingDownload = false;
			}
		},
		userRerpotOnClosed() {
			this.isScriptInfosheetOpen = false;
			this.isLoadingDownload = false;
		}
	},
	computed: {
		useCarbonTemplate() {
			return store.state.myProject.useCarbonTemplate;
		},
		useGreenTable() {
			return store.state.myProject.useGreenTable;
		},
		useReporting() {
			return store.state.myProject.useReporting;
		},
		useCarbonRemoval() {
			return store.state.myProject.useCarbonRemoval;
		},
		useWaste() {
			return store.state.myProject.useWaste;
		},
		useTipOfDay() {
			return store.state.myProject.useTipOfDay;
		},
		useBudget() {
			return store.state.myProject.useBudget;
		},
		useImportExport() {
			return store.state.myProject.useImport || store.state.myProject.useExport;
		},
		useTrans() {
			return store.state.myProject.useTransport;
		},
		useLoc() {
			return store.state.myProject.useLocations;
		},
		useAcc() {
			return store.state.myProject.useAccommodation;
		},
		useCallSheet() {
			return store.state.myProject.useCallSheet;
		},
		useDoc() {
			return store.state.myProject.useDocument;
		},
		needValidation() {
			return (
				store.state.myProject.useExpense ||
				store.state.myProject.usePurchaseOrder ||
				store.state.myProject.useTimesheet ||
				store.state.myProject.useTimesheetPlanning
			);
		},
		isForGreenshotUser() {
			return this.$route.path === '/allUsers';
		},
		isFromProject() {
			if (this.$route.params.id === undefined) {
				return false;
			} else {
				return true;
			}
		},
		isForProject() {
			if (this.$route.path.includes('project')) {
				return true;
			} else {
				return false;
			}
		},
		isForProjects() {
			if (this.$route.path.includes('projects')) {
				return true;
			} else {
				return false;
			}
		},
		canSeeApprove() {
			if (
				store.state.myProfile.upm ||
				store.state.myProfile.gateKeeper ||
				store.state.myProfile.productionAccountant ||
				store.state.myProfile.lineProducer ||
				store.state.myProfile.studio ||
				store.state.myProfile.canValidateI9
			) {
				return true;
			} else {
				return false;
			}
		},
		canSeeUserSetting() {
			return store.canManageCrewList();
		},
		canSeeDocPackage() {
			return store.canManageDocumentPackage();
		},
		lengthUsers() {
			return this.Users.length;
		},
		isPme() {
			return store.isPme();
		},
		isFilm() {
			return store.isFilm();
		},
		optionsForValidationLevel() {
			return this.FormMenu(1113);
		},
		fields() {
			let ret = [];
			if (this.isForProject) {
				ret = [
					// {key: 'id'},
					{
						label: this.FormMSG(4, 'Ref.'),
						key: 'reference',
						sortable: true
					},
					{
						label: this.FormMSG(20, 'Last name'),
						key: 'name',
						sortable: true
					},
					{
						label: this.FormMSG(21, 'First name'),
						key: 'firstName',
						sortable: true
					},
					{
						key: 'departmentName',
						label: this.FormMSG(23, 'Department'),
						sortable: true,
						formatter: (v) => v
					},
					{
						key: 'functionName',
						label: this.FormMSG(24, 'Function'),
						sortable: true,
						formatter: (v) => v
					}
				];
				if (!this.isForProjects) {
					ret.push({
						key: 'permissions',
						label: this.FormMSG(227, 'Screen Access'),
						sortable: false,
						formatter: (v) => v,
						class: 'text-center'
					});
					if (this.useDoc) {
						ret.push({
							key: 'role',
							label: this.FormMSG(277, 'Role'),
							formatter: (v) => v,
							class: 'text-center'
						});
					}
					if (this.needValidation && !this.statusNotifications) {
						ret.push({
							key: 'validationLevel',
							label: this.FormMSG(27, 'Validation rights'),
							sortable: true,
							formatter: (v) => v,
							class: 'text-center'
						});
					}
				}
				ret.push({
					key: 'pict',
					label: this.FormMSG(35, 'Actions'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				});
			} else {
				ret = [
					// {key: 'id'},
					{
						label: this.FormMSG(20, 'Last name'),
						key: 'name',
						sortable: true
					},
					{
						label: this.FormMSG(21, 'First name'),
						key: 'firstName',
						sortable: true
					},
					{
						label: this.FormMSG(22, 'Email'),
						key: 'email',
						sortable: true
					},
					{
						label: this.FormMSG(29, 'Phone'),
						key: 'phone',
						sortable: true
					},
					{
						key: 'isDev',
						label: this.FormMSG(42, 'View TheGreenShot Menu'),
						sortable: true,
						formatter: (v) => v,
						class: 'text-center'
					},
					{
						key: 'isTranslator',
						label: this.FormMSG(43, 'Translator'),
						sortable: true,
						formatter: (v) => v,
						class: 'text-center'
					},
					{
						key: 'Impersonate',
						label: this.FormMSG(28, 'Impersonate'),
						sortable: false,
						formatter: (v) => v,
						class: 'text-center'
					},
					{
						key: 'delUser',
						label: this.FormMSG(39, 'Delete'),
						sortable: false,
						formatter: (v) => v,
						class: 'text-center'
					}
				];
			}

			return ret;
		},
		Mobilefields() {
			let ret = [];
			if (this.isForProject) {
				ret = [
					// {key: 'id'},
					{
						label: this.FormMSG(4, 'Ref.'),
						key: 'reference',
						sortable: true
					},
					{
						label: this.FormMSG(20, 'Last name'),
						key: 'name',
						sortable: true
					},
					{
						label: this.FormMSG(21, 'First name'),
						key: 'firstName',
						sortable: true
					},
					{
						key: 'departmentName',
						label: this.FormMSG(23, 'Department'),
						sortable: true,
						formatter: (v) => v
					},
					{
						key: 'functionName',
						label: this.FormMSG(24, 'Function'),
						sortable: true,
						formatter: (v) => v
					}
				];
				ret.push({
					key: 'pict',
					label: this.FormMSG(35, 'Actions'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				});
			} else {
				ret = [
					// {key: 'id'},
					{
						label: this.FormMSG(20, 'Last name'),
						key: 'name',
						sortable: true
					},
					{
						label: this.FormMSG(21, 'First name'),
						key: 'firstName',
						sortable: true
					},
					{
						label: this.FormMSG(22, 'Email'),
						key: 'email',
						sortable: true
					},
					{
						label: this.FormMSG(29, 'Phone'),
						key: 'phone',
						sortable: true
					},
					{
						key: 'isDev',
						label: this.FormMSG(42, 'View TheGreenShot Menu'),
						sortable: true,
						formatter: (v) => v,
						class: 'text-center'
					},
					{
						key: 'isTranslator',
						label: this.FormMSG(43, 'Translator'),
						sortable: true,
						formatter: (v) => v,
						class: 'text-center'
					},
					{
						key: 'Impersonate',
						label: this.FormMSG(28, 'Impersonate'),
						sortable: false,
						formatter: (v) => v,
						class: 'text-center'
					},
					{
						key: 'delUser',
						label: this.FormMSG(39, 'Delete'),
						sortable: false,
						formatter: (v) => v,
						class: 'text-center'
					}
				];
			}

			return ret;
		},
		userRequestFields() {
			var ret = [];
			ret = [
				// {key: 'id'},
				{
					label: this.FormMSG(20, 'Last name'),
					key: 'userName',
					sortable: true
				},
				{
					label: this.FormMSG(21, 'First name'),
					key: 'userFirstName',
					sortable: true
				},
				{
					label: this.FormMSG(22, 'Email'),
					key: 'email',
					sortable: true
				},
				{
					key: 'createdAt',
					label: this.FormMSG(54, 'Sent on'),
					sortable: true,
					formatter: (value) => {
						var str =
							value.split('T')[0].substring(8) +
							'/' +
							value.split('T')[0].substring(5, 7) +
							' ' +
							value.split('T')[1].substring(0, 2) +
							':' +
							value.split('T')[1].substring(3, 5);
						if (str == '01/01 00:00') {
							return '';
						} else {
							return str;
						}
					}
				},
				{
					key: 'departmentName',
					label: this.FormMSG(23, 'Department'),
					sortable: true,
					formatter: (v) => v
				},
				{
					key: 'functionName',
					label: this.FormMSG(24, 'Function'),
					sortable: true,
					formatter: (v) => v
				},
				{
					key: 'delUserRequest',
					label: this.FormMSG(39, 'Delete'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
			return ret;
		},
		isDev() {
			return store.isDev();
		}
	}
};
</script>
<style>
.dropdown-menu {
	z-index: 1 !important;
}
</style>
<style scoped lang="scss">
.nb-text {
	font-weight: 600 !important;
	font-size: 14px !important;
	letter-spacing: 0.01em !important;
	color: rgba(6, 38, 62, 0.74) !important;
}
.gap-2 {
	gap: 4px;
}
.custom-button-user {
	animation: none;
	outline: none !important;
	border: none !important;
	margin-left: -10px !important;
	background-color: #fff !important;
	color: black !important;
	&:hover,
	&:active,
	&:focus,
	&:focus-visible {
		background-color: #d5d6d7 !important;
		border: none !important;
	}
}
</style>
